import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import HomePage from "./pages/home/HomePage";
import ContactPage from "./pages/contact/ContactPage";
import TestimonialPage from "./pages/testimonial/TestimonialPage";
import TestimonialSubmitPage from "./pages/testimonial/TestimonialSubmitPage";
import AboutPage from "./pages/about/AboutPage";
import CoursesPage from "./pages/courses/CoursesPage";
import BookingPage from "./pages/booking/BookingPage";
import PrivacyPage from "./pages/privacy/PrivacyPage";
import FAQPage from "./pages/faq/FAQPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFoundPage from "./pages/notfound/NotFoundPage";
import SitemapPage from "./pages/sitemap/SitemapPage";
import SponsorPage from "./pages/sponsor/SponsorPage";
import "./assets/styles/light/App.scss";
import { useTranslation } from "react-i18next";
import CookieConsentBanner from "./components/CookieConsent";

// Define Popup props type
interface PopupProps {
  onClose: () => void;
}

// Popup Component
const Popup: React.FC<PopupProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div
      className="popup"
      style={{
        ...popupStyles,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p>{t("home_banner_1")}</p>

      <button
        onClick={onClose}
        type="button"
        className="btn btn-secondary popup-close-btn"
      >
        Close
      </button>
    </div>
  );
};

const popupStyles: React.CSSProperties = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "20px",
  // border: "1px solid #ccc",
  border: "2px solid gold",
  width: "90%",
  maxWidth: "600px",
  zIndex: 1000,
};

const overlayStyles: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 999,
};

export default function App() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setShowPopup(true);
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <BrowserRouter>
      <div className="pt-3 pb-0">
        <Header />

        {/* Overlay and Popup */}
        {showPopup && (
          <>
            <div style={overlayStyles} onClick={handleClose} />
            <Popup onClose={handleClose} />
          </>
        )}

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/sitemap" element={<SitemapPage />} />
          <Route path="/testimonial" element={<TestimonialPage />} />
          <Route
            path="/testimonial/submit"
            element={<TestimonialSubmitPage />}
          />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/booking" element={<BookingPage />} />
          <Route path="/sponsor" element={<SponsorPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/courses" element={<CoursesPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <CookieConsentBanner />

        <Footer />
      </div>
    </BrowserRouter>
  );
}
